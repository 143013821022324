<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['name', {rules: [{required: true, message: '请输入'}]}]" />
        </a-form-item>
        <a-form-item label="图片路径" :labelCol="labelCol" :wrapperCol="wrapperCol" v-show="false">
          <a-input v-decorator="['picture', {rules: [{required: true, message: '请输入'}]}]" />
        </a-form-item>
        <a-form-item label="图片" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <div class="clearfix" style="display: inline-block">
            <a-upload
              name="file"
              accept="image/png, image/jpeg, image/jpg"
              :action="actionUrl"
              list-type="picture-card"
              :file-list="fileList"
              :data="new_multipart_params"
              :before-upload="beforeUpload"
              @preview="handlePreview"
              @change="handleChange"
            >
              <div v-if="fileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  图片上传
                </div>
              </div>
            </a-upload>
            <span v-if="false">提示：图片宽高比为1:1</span>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </a-form-item>
        <a-form-item label="连接" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['link', {rules: [{required: false, message: '请输入'}]}]" />
        </a-form-item>
        <a-form-item label="序号" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number
            :max="10000"
            :min="1"
            v-decorator="['serial', {rules: [{ required: false, message: '请输入' }]}]" />
        </a-form-item>
        <a-form-item label="是否启用" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-switch v-decorator="['is_show', { valuePropName: 'checked' }]" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import { oss_token_list } from '@/api/oss_token'

function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export default {
  name: 'GoodsCategory',
  props: {
    record: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      previewVisible: false,
      actionUrl: '',
      fileList: [],
      fileList2: [],
      previewImage: '',
      previewImage2: '',
      mysort_order: '1',
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      new_multipart_params2: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      }
    }
  },
  beforeCreate () {
    oss_token_list().then(({ data }) => {
      console.log(data, '我是返回值')
      this.new_multipart_params.OSSAccessKeyId = data.accessid
      this.new_multipart_params.policy = data.policy
      this.new_multipart_params.signature = data.signature
      this.actionUrl = 'https://static2.jnzbtz.com'
    })
    oss_token_list().then(({ data }) => {
      console.log(data, '我是返回值')
      this.new_multipart_params2.OSSAccessKeyId = data.accessid
      this.new_multipart_params2.policy = data.policy
      this.new_multipart_params2.signature = data.signature
      this.actionUrl = 'https://static2.jnzbtz.com'
    })
  },
  computed: {
    headers () {
      return {
        'X-Litemall-Admin-Token': '1'
      }
    },
    attributesData () {
      var attributesData = []
      for (var i = 0; i < this.attributes.length; i++) {
        if (this.attributes[i].deleted) {
          continue
        }
        attributesData.push(this.attributes[i])
      }
      return attributesData
    }
  },
  methods: {
    handleLevelChange (value) {
      this.record.level = value
      console.log(this.record.level)
    },
    handleParentLevelChange (value) {
      console.log(value)
    },
    beforeUpload (file) {
      const name = file.name
      const suffix = name.substring(name.lastIndexOf('.') + 1)
      this.new_multipart_params.key = file.uid + '.' + suffix
      // this.form.setFieldsValue({ icon_url: this.new_multipart_params.key })
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('文件过大，请重新上传')
      }
      console.log('--------------------------------------', isJpgOrPng, isLt2M)
      return isJpgOrPng && isLt2M
    },
    // 进口图片
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj, () => {
        })
      }
      console.log('我要预览', file)
      this.previewImage = file.url || file.preview || file.thumbUrl
      this.previewVisible = true
    },
    // 图片
    async handlePreview2 (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj, () => {
        })
      }
      console.log('我要预览', file)
      this.previewImage2 = file.url || file.preview || file.thumbUrl
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      if (file.status === 'removed') {
        this.form.setFieldsValue({ picture: '' })
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.form.setFieldsValue({ picture: this.actionUrl + '/' + this.new_multipart_params.key })
      }
    },
    handleCategoryChange (value) {
      this.goods.categoryId = value[value.length - 1]
    },
    handleCancel: function () {
      this.previewVisible = false
    },
    handleClose (tag) {
      this.keywords.splice(this.keywords.indexOf(tag), 1)
      this.goods.keywords = this.keywords.toString()
    },
    showInput () {
      this.newKeywordVisible = true
      this.$nextTick(_ => {
        this.$refs.newKeywordInput.$refs.input.focus()
      })
    },
    handleInputConfirm () {
      const newKeyword = this.newKeyword
      if (newKeyword) {
        this.keywords.push(newKeyword)
        this.goods.keywords = this.keywords.toString()
      }
      this.newKeywordVisible = false
      this.newKeyword = ''
    },
    handleGoBack () {
      this.$emit('onGoBack')
    },
    uploadOverrun: function () {
      this.$message({
        type: 'error',
        message: '上传文件个数超出限制!最多上传5张图片!'
      })
    },
    handleGalleryUrl (response, file, fileList) {
      if (response.errno === 0) {
        this.goods.gallery.push(response.data.url)
      }
    },
    handleRemove: function (file, fileList) {
      for (var i = 0; i < this.goods.gallery.length; i++) {
        // 这里存在两种情况
        // 1. 如果所删除图片是刚刚上传的图片，那么图片地址是file.response.data.url
        //    此时的file.url虽然存在，但是是本机地址，而不是远程地址。
        // 2. 如果所删除图片是后台返回的已有图片，那么图片地址是file.url
        var url
        if (file.response === undefined) {
          url = file.url
        } else {
          url = file.response.data.url
        }

        if (this.goods.gallery[i] === url) {
          this.goods.gallery.splice(i, 1)
        }
      }
    },
    specChanged: function (label) {
      if (label === false) {
        this.specifications = [
          { specification: '规格', value: '标准', picUrl: '' }
        ]
        this.products = [
          { id: 0, specifications: ['标准'], price: 0.0, number: 0, url: '' }
        ]
      } else {
        this.specifications = []
        this.products = []
      }
    },
    uploadSpecPicUrl: function (response) {
      this.specForm.picUrl = response.data.url
    },
    handleSpecificationShow (row) {
      this.specForm = Object.assign({}, row)
      this.specVisiable = true
    },
    handleSpecificationEdit () {
      this.specForm.updateTime = ''
      for (var i = 0; i < this.specifications.length; i++) {
        const v = this.specifications[i]
        if (v.id === this.specForm.id) {
          this.specifications.splice(i, 1, this.specForm)
          break
        }
      }
      this.specVisiable = false
    },
    handleProductShow (row) {
      this.productForm = Object.assign({}, row)
      this.productVisiable = true
    },
    uploadProductUrl: function (response) {
      this.productForm.url = response.data.url
    },
    handleProductEdit () {
      this.productForm.updateTime = ''
      for (var i = 0; i < this.products.length; i++) {
        const v = this.products[i]
        if (v.id === this.productForm.id) {
          this.products.splice(i, 1, this.productForm)
          break
        }
      }
      this.productVisiable = false
    },
    handleAttributeShow (row) {
      if (row.id) {
        this.attributeForm = Object.assign({}, row)
        this.attributeAdd = false
      } else {
        this.attributeForm = {}
        this.attributeAdd = true
      }
      this.attributeVisiable = true
    },
    handleAttributeAdd () {
      this.attributes.unshift(this.attributeForm)
      this.attributeVisiable = false
    },
    handleAttributeEdit () {
      // 这是一个trick，设置updateTime的值为空，告诉后端这个记录已编辑需要更新。
      this.attributeForm.updateTime = ''
      for (var i = 0; i < this.attributes.length; i++) {
        const v = this.attributes[i]
        if (v.id === this.attributeForm.id) {
          this.attributes.splice(i, 1, this.attributeForm)
          break
        }
      }
      this.attributeVisiable = false
    },
    handleAttributeDelete (row) {
      row.deleted = true
    }
  }
}
</script>
